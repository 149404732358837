import type { HLocation } from '@reach/router';
import { graphql } from 'gatsby';
import * as React from 'react';

import { Layout } from '../components/layout';
import type { TSanityModule } from '../components/sanity-modules';
import { SanityModulesSM } from '../components/sanity-modules-sm';
import { SEO } from '../components/seo';
import type { ISeo } from '../types';
import { HeroOutdoorDecks } from '../components/modules/hero-outdoor-decks';
import { ContactSectionSM } from '../components/modules/contact-section-sm';
import { HeadingWithCtaSM } from '../components/modules/heading-with-cta-sm';
import { CopyWithImageOutdoorDecks } from '../components/modules/copy-with-image-outdoor-decks';
import Checkmark from '../images/checkmark.svg';
import { GoogleMapNormal } from '../components/modules/google-map-normal';
import Carousel from 'nuka-carousel';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import InnerHTML from 'dangerously-set-html-content';

const html = `<div class="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/30YWPsE2Dep4CibaKvfw8H6AMeSUwocARRti5enPOwnfUOgAWeUTkitBzASAqHjk7"><script src="https://webforms.pipedrive.com/f/loader"></script></div>`;
const testhtml = `<script type="text/javascript" src="https://buildertrend.net/leads/contactforms/js/btClientContactForm.js"></script> 
    <iframe src="https://buildertrend.net/leads/contactforms/ContactFormFrame.aspx?builderID=85661" scrolling="no" id="btIframe" style="background:transparent;border:0px;margin:0 auto;width:100%;"></iframe>`;

interface OutdoorDecksPageProps {
  data: {
    sanityHomePage: {
      modules: TSanityModule[];
      seo: ISeo;
    };
  };
  location: HLocation;
}

function FinishedProjectsCarousel() {
  return (
    <div className="bg-dark">
      <div className="w-full px-4 py-12 mx-auto max-w-prose lg:max-w-screen-2xl sm:px-6 lg:px-8 lg:py-24">
        <h2 className="mb-24 text-4xl font-semibold text-center text-light">
          Finished Projects
        </h2>
        <Carousel
          wrapAround={true}
          slidesToShow={1}
          renderCenterLeftControls={({ previousSlide }) => null}
          renderCenterRightControls={({ nextSlide }) => null}
          defaultControlsConfig={{
            pagingDotsStyle: {
              fill: 'red',
            },
            pagingDotsClassName: 'paging-dot',
          }}
        >
          <StaticImage
            src="../images/outdoor-decks-2-new.jpg"
            alt=""
            className="flex-1"
          />
          <StaticImage
            src="../images/outdoor-decks-3-new.jpg"
            alt=""
            className="flex-1"
          />
          <StaticImage
            src="../images/outdoor-decks-4-new2.jpg"
            alt=""
            className="flex-1"
          />
          <StaticImage
            src="../images/outdoor-decks-5-new2.jpg"
            alt=""
            className="flex-1"
          />
          <StaticImage
            src="../images/outdoor-decks-6-new2.jpg"
            alt=""
            className="flex-1"
          />
        </Carousel>
      </div>
    </div>
  );
}

function BulletPoints() {
  return (
    <div className="w-full px-4 py-12 mx-auto max-w-prose lg:pt-6 lg:pb-24 lg:max-w-screen-2xl sm:px-6 lg:px-8">
      <div className="text-dark">
        <h4 className="font-semibold uppercase heading-red">Our range of</h4>
        <h2 className="text-4xl font-semibold">Outdoor Deck Solutions</h2>
        {/* <img src={Checkmark} alt="" className="inline-block w-5 h-5" /> */}
        <div className="flex flex-wrap">
          <div className="w-full lg:w-1/2">
            <ul className="mt-6 ml-6">
              <li className="checkmarkLi">
                We use your choice of timber or composite materials from
                trusted, local suppliers to maintain the utmost quality
              </li>
              <li className="mt-6 checkmarkLi">
                We craft and create pool decks to make your pool area safe,
                slip-resistant, and accessible
              </li>
              <li className="mt-6 checkmarkLi">
                Koori Carpentry can create and install pergolas and verandas to
                so you can enjoy the outdoors no matter what the weather says
              </li>
              <li className="mt-6 checkmarkLi">
                Get the privacy you deserve with wood-crafted Privacy Screens,
                keeping prying eyes out of sight and out of mind
              </li>
            </ul>
          </div>
          <div className="w-full lg:w-1/2">
            <ul className="mt-6 ml-6">
              <li className="checkmarkLi">
                Fence off areas and keep the kids and pets safe with Timber
                Fencing created and installed by Koori Carpentry
              </li>
              <li className="mt-6 checkmarkLi">
                Our qualified and friendly carpenters have years of experience
                and are based in Port Macquarie
              </li>
              <li className="mt-6 checkmarkLi">
                Add a touch of luxury with balustrading using timber framing,
                stained and finished to your specifications
              </li>
              <li className="mt-6 checkmarkLi">
                We offer FREE QUOTES for all outdoor deck projects – just ask
                one of our friendly team for more information
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

function OutdoorDecksPage({
  data,
  location,
}: OutdoorDecksPageProps): JSX.Element {
  const { modules, seo } = data.sanityHomePage;
  return (
    <>
      <SEO
        title="Outdoor Decks"
        image={seo.shareGraphic?.asset.url}
        pathname={location.pathname}
      />
      <Layout>
        <HeroOutdoorDecks />
        <div className="relative px-24 py-12 mx-auto -mt-0 text-black bg-white shadow-xl lg:-mt-32">
          <div className="mx-auto ">
            <h2 className="text-2xl font-bold text-center text-black md:text-4xl">
              Get in touch for your
              <br />
              FREE QUOTE{' '}
            </h2>
            <InnerHTML html={testhtml} className="mt-6" />
          </div>
        </div>
        <CopyWithImageOutdoorDecks />
        <BulletPoints />
        <FinishedProjectsCarousel />
        <HeadingWithCtaSM />
        <SanityModulesSM modules={modules} />
        {/* ^ this is only for the services grid */}
        <HeadingWithCtaSM />
        {/* <ContactSectionSM /> */}
        <GoogleMapNormal />
      </Layout>
    </>
  );
}

export const query = graphql`
  query OutdoorDecksPageQuery {
    sanityHomePage(_id: { eq: "homePage" }) {
      modules {
        ... on SanityHero {
          ...SanityHeroParts
        }
        ... on SanityCopyWithImage {
          ...SanityCopyWithImageParts
        }
        ... on SanityServicesGrid {
          ...SanityServicesGridParts
        }
        ... on SanityHeadingWithCta {
          ...SanityHeadingWithCtaParts
        }
        ... on SanityFrequentlyAskedQuestions {
          ...SanityFrequentlyAskedQuestionsParts
        }
        ... on SanityContactForm {
          ...SanityContactFormParts
        }
        ... on SanityGoogleMap {
          ...SanityGoogleMapParts
        }
      }
      seo {
        metaDesc
        metaTitle
        shareDesc
        shareGraphic {
          asset {
            url
          }
        }
        shareTitle
      }
    }
  }
`;

export default OutdoorDecksPage;
