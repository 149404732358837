import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { Link } from 'gatsby';

import type { ISanityHero } from '../../fragments/sanity-hero-parts';
import { classNames } from '../../utils/classnames';
import { ButtonLink } from '../button-link';

function HeroOutdoorDecks(): JSX.Element {
  const maxHeight = 'max-h-[36rem]';
  return (
    <div className={classNames(maxHeight, 'relative overflow-hidden')}>
      <div className="md:aspect-w-16 md:aspect-h-9">
        <div
          className={classNames(
            maxHeight,
            'absolute inset-0 flex md:inset-auto'
          )}
        >
          <StaticImage
            src="../../images/outdoor-decks-new-hero.jpg"
            alt={''}
            className="flex-1"
          />
        </div>

        <div
          className={classNames(
            maxHeight,
            'relative flex flex-col items-center justify-center py-12 text-center bg-opacity-25 bg-dark px-4 sm:px-6 lg:px-8'
          )}
        >
          <h1 className="max-w-4xl font-semibold text-light">
            <span className={`text-5xl inline-block leading-tight`}>
              Top Quality
            </span>
            <br />
            <span className={`text-5xl uppercase inline-block leading-tight`}>
              Outdoor Decks
            </span>
            <p
              className={`text-white prose inline-block tracking-wide font-light mt-6`}
            >
              Entertain, add value, and get more from your home with a quality
              outdoor deck from Koori Carpentry
            </p>
          </h1>
          {/* <p className="mt-2">
            <Link
              to={'/contact-us/'}
              className="inline-block px-6 py-2 text-base font-semibold tracking-wider text-white uppercase border-2 bg-primary border-primary"
            >
              Contact Us
            </Link>
          </p> */}
        </div>
      </div>
    </div>
  );
}

export { HeroOutdoorDecks };
